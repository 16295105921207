<template>
  <div>
    <v-form v-model="valid">
      <v-row>
        <v-col class="pa-4">
          <h3
            v-if="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotFieldservice')
            "
          >
            Nachunternehmer:
          </h3>
          <v-select
            v-model="gfNumber"
            label="GF-NR"
            required
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
          ></v-select>
          <v-autocomplete
            v-model="taLocation"
            label="TA-Lage"
            required
            :items="[
              'Keller',
              'Garage',
              'Erdgeschoss',
              '1.Stock',
              '2.Stock',
              '3.Stock',
              '4.Stock'
            ]"
          ></v-autocomplete>
          <v-text-field
            v-model="mfgSplicePanel"
            label="MFG-Spleißpanel"
            required
            :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
            v-model="mfgPanelPort"
            label="MFG-Panelport"
            required
            :rules="[requiredRule]"
          ></v-text-field>
          <!-- <v-text-field
            v-model="mfgDamping"
            label="MFG-Dämpfung"
          ></v-text-field> -->
          <!-- <v-text-field
            v-model="ontsn"
            label="ONT-Seriennummer"
            required
          ></v-text-field> -->
          <v-combobox
            ref="ontSn"
            v-model="ontSn"
            :items="ontItems"
            :loading="loadingOnts"
            hide-no-data
            :search-input.sync="search"
            :item-text="getOntText"
            :item-value="serialNumber"
            label="ONT-Seriennummer"
            append-icon=""
            placeholder="ONT-Seriennummer eingeben"
            clearable
            return-object
            key-name="serialNumber"
            :rules="[ontRule]"
          ></v-combobox>
          <v-alert
            type="info"
            text
            border="left"
          >
            ONT-Seriennummer nicht verfügbar? Wenden Sie sich bitte an
            <a href="mailto:access@goetel.de">access@goetel.de</a>
          </v-alert>
          <!-- {{ ontItems }}
          <v-otp-input
            v-model="ontsn2"
            length="12"
            label="ONT-Seriennummer"
            required
          >
          </v-otp-input>
          <v-otp-input v-model="otp"></v-otp-input> -->
          <v-checkbox
            v-model="otdrProtocol"
            label="OTDR-Protokoll vorhanden"
          ></v-checkbox>
          <v-checkbox
            v-model="proofOfInstallation"
            label="Montagenachweis vorhanden"
          ></v-checkbox>
          <v-text-field v-model="remark" label="Bemerkung"></v-text-field>
        </v-col>
        <!-- <v-col
          v-if="
            userGroups.includes('camunda-admin') ||
            userGroups.includes('LgSpotFieldservice')
          "
          class="pa-4"
        >
          <h3>Fieldservice:</h3>
          <v-combobox
            ref="msan"
            v-model="msan"
            :items="msanItems"
            :loading="loadingMsans"
            hide-no-data
            :search-input.sync="searchMsan"
            :item-text="getMsanText"
            :item-value="serialNumber"
            label="Device-Hostname(MSAN)"
            append-icon=""
            placeholder="Device-Hostname(MSAN) eingeben"
            clearable
            return-object
            key-name="serialNumber"
            :rules="[ontRule]"
          ></v-combobox>
          <v-text-field
            v-model="deviceSlot"
            label="Device-Slot"
            required
            type="number"
            :rules="[numericRule]"
          ></v-text-field>
          <v-text-field
            v-model="devicePort"
            label="Device-Port"
            required
            type="number"
            :rules="[numericRule]"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-btn
        color="primary"
        class="float-right"
        @click="saveSupplyUnitConnection"
      >
        Speichern
      </v-btn>
      <br />
      <br />
    </v-form>
  </div>
</template>

<script>
// import { HTTP } from '@/components/_generic/Backend.js';
import axios from 'axios';
import { HTTP } from '@/main/httpClient.js';
import debounce from 'debounce';
import _ from 'lodash';
import NotificationObject from '@/main/NotificationObject.js';
// import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'SupplyUnitConnectionForm',
  components: {},
  props: {
    homeId: {
      type: String,
      required: true
    },
    gAgs: {
      type: String,
      required: true
    },
    existingSupplyUnit: {
      type: Array,
      required: false,
      default: null
    }
  },
  data: () => ({
    ontsn2: 'GNXS',
    addresses: undefined,
    toggles: [],
    activeItem: {},
    city: null,
    district: [],
    districtItems: [],
    city3: null,
    cities: [],
    city2: undefined,
    cities2: [],
    cityItems2: undefined,
    timer: null,
    loading: false,
    isUpdating: false,
    search: '',
    loadingCities: false,
    loadingDistricts: false,
    supplyUnitId: undefined,
    propertyStatusId: undefined,
    gfNumber: null,
    taLocation: null,
    mfgSplicePanel: null,
    mfgPanelPort: null,
    mfgDamping: null,
    ontSn: 'GNXS',
    otdrProtocol: false,
    proofOfInstallation: false,
    deviceHostname: null,
    deviceSlot: null,
    devicePort: null,
    remark: null,
    ontItems: [],
    loadingOnts: false,
    msan: undefined,
    msanItems: [],
    loadingMsans: false,
    searchMsan: ''
    // selectAllDistricts: false
  }),
  computed: {
    // cityItems2() {
    //   return this.cities.length ? this.cities.city : [];
    // },
    userGroups() {
      return this.$auth.user().userRoles;
    },
    districts() {
      if (this.districtItems.length) {
        const districtGroup = _.groupBy(this.districtItems, 'district');
        const districtArray = [];
        let districtName;
        // districtGroup = _.groupBy(this.districtItems, 'district');
        console.log(districtGroup);

        Object.keys(districtGroup).forEach((key) => {
          console.log(key, districtGroup[key][0].city);
          // console.log(ke)
          districtName = key.length ? key : districtGroup[key][0].city;
          districtArray.push(districtName);
        });
        return districtArray.sort();
      } else {
        return [];
      }
    },
    selectAllDistricts() {
      return this.district.length === this.districts.length;
    },
    selectSomeDistrict() {
      return this.district.length > 0 && !this.selectAllDistricts;
    },
    icon() {
      if (this.selectAllDistricts) return 'mdi-close-box';
      if (this.selectSomeDistrict) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    search(value) {
      if (!value) {
        return;
      }
      if (value.length > 5) {
        debounce(this.makeSearch, 900)(value, this);
      }
    },
    searchMsan(value) {
      if (!value) {
        return;
      }
      if (value.length > 0) {
        debounce(this.makeSearchMsan, 900)(value, this);
      }
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    city(value) {
      console.log(value.city);
      if (!value) {
        return;
      }
      if (value.city.length >= 3) {
        this.getDistricts(value.city);
      }
    },
    existingSupplyUnit: {
      handler: function (newValue) {
        if (newValue && newValue.length) {
          this.gfNumber = newValue[0].gfNumber;
          this.taLocation = newValue[0].taLocation;
          this.mfgSplicePanel = newValue[0].mfgSplicePanel;
          this.mfgPanelPort = newValue[0].mfgPanelPort;
          this.mfgDamping = newValue[0].mfgDamping;
          this.ontSn = newValue[0].ontSn;
          this.otdrProtocol = newValue[0].otdrProtocol;
          this.proofOfInstallation = newValue[0].proofOfInstallation;
          this.deviceHostname = newValue[0].deviceHostname;
          this.deviceSlot = newValue[0].deviceSlot;
          this.devicePort = newValue[0].devicePort;
          this.remark = newValue[0].remark;
          this.msan = newValue[0].msan;
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.getAddresses();
    if (this.existingSupplyUnit && this.existingSupplyUnit.length) {
      this.gfNumber = this.existingSupplyUnit[0].gfNumber;
      this.taLocation = this.existingSupplyUnit[0].taLocation;
      this.mfgSplicePanel = this.existingSupplyUnit[0].mfgSplicePanel;
      this.mfgPanelPort = this.existingSupplyUnit[0].mfgPanelPort;
      this.mfgDamping = this.existingSupplyUnit[0].mfgDamping;
      this.ontSn = this.existingSupplyUnit[0].ontSn;
      this.otdrProtocol = this.existingSupplyUnit[0].otdrProtocol;
      this.proofOfInstallation = this.existingSupplyUnit[0].proofOfInstallation;
      this.deviceHostname = this.existingSupplyUnit[0].deviceHostname;
      this.deviceSlot = this.existingSupplyUnit[0].deviceSlot;
      this.devicePort = this.existingSupplyUnit[0].devicePort;
      this.remark = this.existingSupplyUnit[0].remark;
      this.msan = this.existingSupplyUnit[0].msan;
    }
  },
  methods: {
    saveSupplyUnitConnection: function () {
      HTTP.get('/past/geo/create-supply-unit-connection', {
        params: {
          gAgs: this.gAgs,
          homeId: this.homeId,
          gfNumber: this.gfNumber,
          taLocation: this.taLocation,
          mfgSplicePanel: this.mfgSplicePanel,
          mfgPanelPort: this.mfgPanelPort,
          mfgDamping: this.mfgDamping,
          ontSn: this.ontSn?.serialNumber,
          proofOfInstallation: this.proofOfInstallation,
          otdrProtocol: this.otdrProtocol,
          remark: this.remark,
          lastEditor: this.$auth.user().userName
        }
      })
        .then((resp) => {
          if (resp && resp.data) {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', resp.data)
            );
            this.$emit('close');
            this.gfNumber = null;
            this.taLocation = null;
            this.mfgSplicePanel = null;
            this.mfgPanelPort = null;
            this.mfgdamping = null;
            this.ontSn = undefined;
            this.proofOfInstallation = false;
            this.otdrProtocol = false;
            this.remark = null;
          } else if (resp) {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', resp)
            );
          }
        })
        .catch((err) =>
          this.$store.commit(
            'addNotification',
            new NotificationObject('error', err.response.data)
          )
        )
        .finally(() => {});
    },
    getAddresses() {
      axios
        .get('https://localhost:8800/addresses', {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
          }
        })
        .then((response) => (this.addresses = response.data))
        .catch((error) => console.log(error));
    },
    makeSearch: async (value, self) => {
      if (!value) {
        self.ontItems = [];
        self.ontSn = '';
      }
      if (self.locadingOnts) {
        return;
      }
      self.locadingOnts = true;
      if (isNaN(parseInt(value)) || value.length >= 3) {
        HTTP.get(
          '/components/cpeDevices/bySerialNumbersInfix?deviceType=ONT&serialNumberInfix=' +
            value
        )
          .then((response) => (self.ontItems = response.data))
          .catch((error) => console.log(error))
          .finally(() => (self.locadingOnts = false));
      }
    },
    makeSearchMsan: async (value, self) => {
      if (!value) {
        self.msanItems = [];
        self.msan = '';
      }
      if (self.locadingMsans) {
        return;
      }
      self.locadingMsans = true;
      if (isNaN(parseInt(value))) {
        HTTP.get('components/msanChassis/availableElements')
          .then((response) => (self.msanItems = response.data))
          .catch((error) => console.log(error))
          .finally(() => (self.locadingMsans = false));
      }
    },
    getOntText(item) {
      let ontLabel;
      if (this.ontItems && this.ontItems.length >= 1) {
        // ontLabel = `${item.serialNumber}   (${item.cpeDeviceType}, ${item.model})`;
        ontLabel = `${item.serialNumber}`;
      }
      return ontLabel;
    },
    getMsanText(item) {
      let msanLabel;
      if (this.msanItems && this.msanItems.length >= 1) {
        // ontLabel = `${item.serialNumber}   (${item.cpeDeviceType}, ${item.model})`;
        msanLabel = `${item.searchLabel}`;
      }
      return msanLabel;
    },
    getCities() {
      const _this = this;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        axios
          .get(
            'http://localhost:3000/api/postcode-data?where[city][startsWith]=' +
              _this.city,
            {
              headers: {
                Authorization: 'Basic YWRtaW46YWRtaW4=',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods':
                  'GET,PUT,POST,DELETE,PATCH,OPTIONS'
              }
            }
          )
          .then((response) => (_this.cities = response.data))
          .catch((error) => console.log(error));
      }, 800);
    },
    getCities2() {
      this.loading = true;
      axios
        .get(
          'http://localhost:3000/api/postcode-data?where[city][startsWith]=' +
            this.city2,
          {
            headers: {
              Authorization: 'Basic YWRtaW46YWRtaW4=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then(
          (response) => (this.cities2 = response.data),
          (this.loading = false)
        )
        .catch((error) => console.log(error));
    },
    getDistricts(city) {
      this.loading = true;
      axios
        .get(
          'http://localhost:3000/api/postcode-data?where[city][equals]=' + city,
          {
            headers: {
              Authorization: 'Basic YWRtaW46YWRtaW4=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          }
        )
        .then(
          (response) => (this.districtItems = response.data),
          (this.loading = false)
        )
        .catch((error) => console.log(error));
    },
    toggleActive(item) {
      if (this.activeItem[item.id]) {
        this.removeActiveItem(item);

        return;
      }

      this.addActiveItem(item);
    },
    addActiveItem(item) {
      this.activeItem = Object.assign({}, { [item.id]: item });
    },
    removeActiveItem(item) {
      delete this.activeItem[item.id];
      this.activeItem = Object.assign({}, this.activeItem);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllDistricts) {
          this.district = [];
        } else {
          this.district = this.districts.slice();
        }
      });
    },
    // validation rules
    numericRule: (v) => {
      if (!v) return 'Bitte geben Sie eine Nummer ein';
      if (!(v && /\d+/i).test(v)) return 'Bitte geben Sie eine Nummer ein';
      return true;
    },
    requiredRule: (v) => {
      return !!v || 'Bitte füllen Sie dieses Feld aus';
    },
    ontRule: (v) => {
      if (!v) return 'Bitte geben Sie eine korrekte ONT-Seriennummer ein';
      return true;
    }
  }
};
</script>
<style lang="scss" scoped>
.addr-item {
  display: block;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 3px 0;
}
.addr-details {
  display: none;
  transition: all 0.3s ease-in-out;
}
.addr-item:hover .addr-details {
  display: block;
}
</style>
